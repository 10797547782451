import React, { useState } from 'react'
import './css/Home.css';
import Create from './Create'
import { notification} from 'antd';
import { ImportOutlined, ExportOutlined } from '@ant-design/icons';
import { signout } from '../api/auth'
import {
    Link,
} from 'react-router-dom';
import PlantList from '../Component/PlantList'
import UpdateLink from '../Component/UpdateLink'

function Navbar(props) {
    let token = localStorage.getItem("token");
    let loginBtn = (
        <Link to="/login" className="nav-bar-btn-admin">
            <div id="navbar-account">{/*Đăng nhập*/}<ImportOutlined rotate='180'/></div>
        </Link>
    )

    let logoutBtn = (
        <span className="nav-bar-btn-admin" onClick={ async () => {
            try {
                let res = await signout();
                if(res.status === 200){
                    notification.success({
                        duration: 2,
                        message: "Thành công",
                        description: res.data.message
                    })
                    window.location.href = "/"
                    localStorage.removeItem("token")
                }
            } catch (error) {
                window.location.href = "/"
                localStorage.removeItem("token")
            }
        }}>{/*Đăng xuất*/}<ExportOutlined /></span> 
    )
    return (
        <div className="navbar">
            <div id="nav__btn-action">
               <div className="nav-logo">
                    <Link to="/home-page">
                            <img className="logo none" src="/logo512.png" alt="logo-cendi"/>
                            <img className="logo logo-long" src="/logocendi-long.png" alt="logo-cendi"/>
                    </Link>
               </div>
                <div className="nav-bar-left">
                    <div>
                        <Link className="nav-bar-btn-admin" to="/home-page">
                            <div id="navbar-home-page">Trang chủ</div>
                        </Link>
                    </div>
                    <div>
                        <Link className="nav-bar-btn-admin" to="/gioi-thieu">
                            <div id="navbar-about">Giới thiệu</div>
                        </Link>
                    </div>
                    <div>
                        <Link className="nav-bar-btn-admin" to="/du-an">
                            <div id="navbar-home">Dự án</div>
                        </Link>
                    </div>
                    <div className="nav-bar-btn-admin">
                        <div id="navbar-map" style={{cursor:"pointer"}} onClick={() => {
                            window.location.href = (process.env.NODE_ENV !== "production" ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD  ) +"/map"
                        }}>Bản đồ</div>
                    </div>
                    <div>
                        <Link  className="nav-bar-btn-admin" to='/lien-he'>
                            <div id="navbar-contact">Liên hệ</div>
                        </Link>
                    </div>
                    <div>
                        {token ? logoutBtn : loginBtn}
                    </div>
                </div>
            </div>
        </div>
    )
}
function Footer() {
    return (
        <div className="footer"></div>
    )
}

function Home() {
    let [menu, setMenu] = useState("suggest");
    let token = localStorage.getItem("token")
   
    return (
        <div className="home">
            < Navbar />
            <div className="menu">
                <button className={menu === "suggest" ? "bg-menu-button" : null} onClick={() => setMenu("suggest")}>Đề xuất</button>
                <button className={menu === "doing" ? "bg-menu-button" : null} onClick={() => setMenu("doing")}>Đang làm</button>
                <button className={menu === "done" ? "bg-menu-button" : null} onClick={() => setMenu("done")}>Đã kết thúc</button>
                <button className={menu === "all" ? "bg-menu-button" : null} onClick={() => setMenu("all")}>Tất cả</button>
            </div>
            <div className="btn-of-admin">
                {token ? <UpdateLink /> : null}
                {token ? < Create /> : null}
            </div>
            {menu === "doing" ? <PlantList status="doing"/> : null }
            {menu === "suggest" ? <PlantList status="suggest"/> : null }
            {menu === "done" ? <PlantList status="done"/> : null }
            {menu === "all" ? <PlantList status="all"/> : null }
        </div>
    )
}
let ComponentRender = {
    Home,
    Footer,
    Navbar
};
export default ComponentRender