import React from "react";
import { Form, Input, Button, notification } from "antd";
import "./Login.css";
import { login } from "../../api/auth";
import { useHistory } from "react-router-dom";
function Login() {
  const [form] = Form.useForm();
  let history = useHistory();
  let onFinish = async (valueForm) => {
    if (valueForm.email || valueForm.password) {
      try {
        let body = {
          email: valueForm.email,
          password: valueForm.password,
        };
        let response = await login(body);
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          notification.success({
            duration: 2,
            message: "Thành công",
            description: response.data.message,
          });
          history.push("/");
        } else {
          notification.error({
            duration: 2,
            message: "Không thành công",
            description: response.data.message,
          });
        }
      } catch (error) {
        notification.error({
          duration: 2,
          message: "Không thành công",
          description: error.message,
        });
      }
    }
  };
  return (
    <div className="wp-login">
      <div className="form__login">
        <div className="form__container">
          <h1 style={{ textAlign: "center" }}>Đăng nhập</h1>
          <Form form={form} name="login" onFinish={onFinish}>
            <p className="form__login-label">Email</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập email",
                },
              ]}
            >
              <Input className="form__login-input" placeholder="Email" />
            </Form.Item>
            <p className="form__login-label">Mật khẩu</p>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mật khẩu",
                },
              ]}
            >
              <Input
                type="password"
                className="form__login-input"
                placeholder="Mật khẩu"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" className="btn-login" htmlType="submit">
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
