import React,{useState, useEffect} from 'react'
import CreateAndUpdateContact from '../../Component/CreateAndUpdateContact'
import Home from '../../Component/Home'
import {getContact} from '../../api/contact'

function Contact() {
  let token = localStorage.getItem("token")
  const[job,setJob] = useState('create')
  const[show,setShow] = useState({})
  useEffect(() => {
    async function getInfo(){
      try {
        let data = await getContact()
        if(data.data.data.length){
          setJob('update')
          setShow(data.data.data[0])
        }
      } catch (error) {
        console.log(error)
      }
    }
    getInfo()
  }, [])

  function render(){
    if(show){
      return {__html: show.content}
    }
  }
  return (
    <div className='aboutContainer'>
      <Home.Navbar />
      <div className='background'>
        <div className='content'>
          <h1 className='title'>{show?show.title:null}</h1>
          <p className="item-content" dangerouslySetInnerHTML={render()}></p>
        </div>
        {token ? <CreateAndUpdateContact job={job} show={show}/> : null}
      </div>
    </div>
  )
}

export default Contact
