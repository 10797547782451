import "./App.css";
import React from "react";
import "./App.css";
import Home from "./Component/Home";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { PublicRoute } from "./Component/Routers/index";
import LoginPage from "./views/Login/Login";
import Detail from "./views/Detail/Detail";
import HomePage from "./Component/HomePage";
import About from "./views/About/About";
import Contact from "./views/Contact/Contact";

function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact={true} path="/du-an" component={Home.Home} />
        <PublicRoute exact={true} path="/home-page" component={HomePage} />
        <PublicRoute exact={true} path="/gioi-thieu" component={About} />
        <PublicRoute exact={true} path="/gioi-thieu/:slug" component={About} />
        <PublicRoute exact={true} path="/login" component={LoginPage} />
        <PublicRoute exact={true} path="/lien-he" component={Contact} />
        <PublicRoute exact={true} path="/du-an/:id" component={Detail} />
        <Redirect from="/" to="/home-page" />
      </Switch>
    </Router>
  );
}

export default App;
