import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../Component/css/Create.css'
import { Modal, Button, Form, Input, Select, notification, DatePicker, Space } from 'antd';
import UploadListImage from './upload/UploadList';
import UpLoadThumnail from './upload/UpLoadThumnail';
import {createPlant} from '../api/plant';
const { TextArea } = Input;
const { Option } = Select;


function Create(props) {
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [listImage, setListImage] = useState([]);
    const [thumbnail, setThumbnail] = useState({});
    const [year, setYear] = useState(0)
    const [dataCk, setDataCk] = useState()
    const showModal = () => {
        setIsModalCreate(true);
    };
    function onChange(date, dateString) {
        setYear(dateString)
    }
    let onFinish = async (value) => {
        let checkThumbnailEmpty = thumbnail && Object.keys(thumbnail).length === 0 && thumbnail.constructor === Object;
        let checkListImageEmpty = listImage && listImage.length === 0 
        if(checkThumbnailEmpty){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng hãy đăng ảnh đại diện cho bài viết",
            })
        }else if(year === 0){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng hãy nhập số năm bắt đầu",
            })
        }else if(checkListImageEmpty){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng hãy đăng ảnh chi tiết",
            })
        }else if(isNaN(Number(value.price))){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Kinh phí vui lòng hãy nhập số",
            })
        }else{
            let body = {
                title: year + value.title,
                content: dataCk,
                thumbnail: thumbnail,
                imageDetail: listImage,
                status: value.status,
                price: value.price
            }
            try {
                let response = await createPlant(body);
                if(response.status === 200){
                    notification.success({
                        duration: 2,
                        message: "Thành công",
                        description: response.data.message,
                    })
                    setIsModalCreate(false);
                    window.location.href = "/du-an"
                }
    
            } catch (error) {
                if(error.response.status === 403) window.location.href = '/login'
                notification.error({
                    duration: 2,
                    message: "Không thành công",
                    description: error.message,
                  })
            }
        }
       
    }
    let onCancel = () => {
        setIsModalCreate(false);
    }
    return (
        <div className="btn-modal-create"> 
            <Button type="primary" onClick={showModal} className="create-plant-btn">
                Tạo dự án
            </Button>
            <Modal 
                title="Tạo dự án" 
                visible={isModalCreate} 
                onCancel={onCancel}
                footer={null}
            >
            <div className="content-create">
            <Form
                name="create-plant"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Tiêu đề"
                    name="title"
                    rules={[{ required: true, message: 'Vui lòng hãy nhập tiêu đề!' }]}
                >
                    <TextArea placeholder="Tiêu đề"/>
                </Form.Item>
                <Form.Item name="status" label="Năm bắt đầu dự án:" rules={[{ required: true, message: 'Vui lòng hãy chọn số năm!' }]}>
                    <Space direction="vertical">
                        <DatePicker onChange={onChange} picker="year" placeholder="Chọn năm" />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Nội dung:"
                    name="content"
                    rules={[{ required: true, message: 'Vui lòng hãy nhập nội dung!' }]}
                >
                    <CKEditor
                        config={{         
                            toolbar: ['heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList',
                             '|', 'undo', 'redo']
                        }}
                        editor={ ClassicEditor }
                        data=""
                        onBlur={ ( event, editor ) => {
                            const data = editor.getData();
                            setDataCk(data)
                        } }
                    />
                </Form.Item>
                <Form.Item name="status" label="Trạng thái:" rules={[{ required: true, message: "Vui lòng hãy chọn trạng thái !" }]}>
                    <Select
                        placeholder="Vui lòng hãy chọn trạng thái"
                        allowClear
                    >
                        <Option value="suggest">Đề xuất</Option>
                        <Option value="doing">Đang làm</Option>
                        <Option value="done">Đã kết thúc</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="price" label="Kinh phí dự kiến:"   rules={[{ required: true, message: 'Vui lòng hãy nhập kinh phí !' }]}>
                    <Input placeholder="Vui lòng hãy kinh phí" type="text"/>
                </Form.Item>
                <Form.Item name="thumbnail" label="Đăng ảnh đại diện">
                    <UpLoadThumnail setThumbnail={setThumbnail}/>
                </Form.Item>
                <Form.Item name="imageDetail" label="Đăng ảnh chi tiết">
                    <UploadListImage setListImage={setListImage}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                         Xác nhận
                    </Button>
                    <Button type="primary" htmlType="submit" style={{background: "white !important", marginLeft: '10px'}} onClick={onCancel}>
                         Hủy
                    </Button>
                </Form.Item>
                </Form>
            </div>
            </Modal>
        </div>
    )
}
export default Create