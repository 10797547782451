import React, { useState } from 'react'
import '../Component/css/Create.css'
import { Modal, Button, Form, Input, notification } from 'antd';
import { updateGGLink } from '../api/config'

const { TextArea } = Input;


function UpdateLinkModal(props) {
    const [isModalCreate, setIsModalCreate] = useState(false);

    const showModal = () => {
        setIsModalCreate(true);
    };
    let onFinish = async (value) => {
        try {
            await updateGGLink(value.link)
            notification.success({
                duration: 2,
                message: "Sửa link thành công",
            })
            setIsModalCreate(false);
        } catch (error) {
            if(error.response.status === 403) window.location.href = '/login'
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Cập nhật link không thành công",
            })
        }
    }
    let onCancel = () => {
        setIsModalCreate(false);
    }
    return (
        <div className="btn-modal-create btn-link-change"> 
            <span className="btn-google-form" type="primary" onClick={showModal}>
                Google Form
            </span>
            <Modal 
                title="Đổi Link GG Form" 
                visible={isModalCreate} 
                onCancel={onCancel}
                footer={null}
            >
            <div className="content-create">
                <Form
                    name="create-plant"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Link GG"
                        name="link"
                        rules={[{ required: true, message: 'Vui lòng nhập Link!' }]}
                    >
                        <TextArea placeholder="title"/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                         Xác nhận
                    </Button>
                </Form>
            </div>
            </Modal>
        </div>
    )
}
export default UpdateLinkModal