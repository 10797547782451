import React,{useEffect, useState} from 'react'
import AddAbout from '../../Component/AddAbout'
import AboutList from '../../Component/AboutList'
import Home from '../../Component/Home'
import {getAllAbout, getAboutBySlug} from '../../api/about'
import './About.css'

export default function About() {
  let token = localStorage.getItem("token")
  let link = window.location.href.split('/gioi-thieu/')[1] || ''
  const [aboutList,setAboutList]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [showAbout,setShowAbout]=useState({})
  const [slug,setSlug]=useState(link)
  useEffect( () => {
    async function getAbout (){
      try {
        let data = await getAllAbout()
        setAboutList(data.data.data)
        if(link){
          let dataBySlug = await getAboutBySlug(link)
          setShowAbout(dataBySlug.data.data)
          for(let i = 0;i<data.data.data.length;i++){
            if(data.data.data[i].id === dataBySlug.data.data.id){
              setCurrentPage(i+1)
              break
            }
          }
        }else{
          setShowAbout(data.data.data[0])
          setCurrentPage(1)
        }
      } catch (error) {
        console.log(25,error);
      }
    }
    getAbout()
  }, [])     

  async function changePage(page){
    let offset = page -1
    setCurrentPage(page)
    let data = await getAllAbout(offset,1)
    setSlug(data.data.data[0].slug)
    setShowAbout(data.data.data[0])
  }

  function renderAbout(){
    if(showAbout){
      return {__html: showAbout.content}
    }
  }
  return (
    <div className='aboutContainer'>
      <Home.Navbar />
      <div className='background'>
        <div className='content'>
          <h1 className='title'>{showAbout?showAbout.title:null}</h1>
          <p className="item-content" dangerouslySetInnerHTML={renderAbout()}></p>
        </div>
        {token ? 
        <div className='btnGroup'>
          <AddAbout job='Thêm mới thông tin'/>
          <AddAbout job='Chỉnh sửa thông tin' data={showAbout}/>
        </div>: null}
        <AboutList page={aboutList} changePage={changePage} currentPage={currentPage}/>
      </div>
    </div>
  )
}
