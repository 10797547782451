import React, { useEffect, useState } from "react";
import "./css/Home.css";
import { Pagination, Row } from "antd";
import { getPage } from "../api/plant";
import Plant from '../Component/Plant'

function PlantList(props) {
  let [data, setData] = useState([]);
  let [status] = useState(props.status);
  let [totalPlant, setTotalPlant] = useState(0);
  let [page, setPage] = useState(1)
  
  useEffect(function () {
    let getData = async () => {
      try {
        let res = await getPage(1, 8, status);
        setData(res.data.data.rows);
        setTotalPlant(res.data.data.count);
      } catch (error) {
      }
    };
    getData();
  }, [status]);

  return (
    <>
      <div className="plant-list">
        <Row gutter={[{ xs: 0, sm: 8, md: 10, lg: 16 }, { xs: 16, sm: 16, md: 24, lg: 32 }]} justify="flex-start">
          {data.length
            ? data.map((item, index) => {
                return <Plant data={item} key={index} />;
              })
            : null}
        </Row>
      </div>
      {data.length ? (
        <div className="pagination">
          <Pagination
            defaultCurrent={1}
            total={totalPlant}
            pageSize={8}
            current={page}
            onChange={async (page, pageSize) => {
              setPage(page)
              let res = await getPage(page, 8, status);
              setData(res.data.data.rows);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default PlantList;
