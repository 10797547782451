import React from 'react'
import { Card, Col, Row } from 'antd';
import { handleURLImage } from '../utils/handleURLImage';
import {
  Link
} from 'react-router-dom';
function Plant(props) {
  let checkStatus = () => {
    return <Row>
          <div  className="plant-footer" style={{ height: "40px"}}>
            <Col span={16} className="center-vertical">  <p className="plant-cost">Kinh phí dự kiến: {props.data.price.toLocaleString('en-EN', {
              style : 'currency',
              currency : "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              })} </p>  </Col>
            <Col span={8}>
              <button className="component-btn-join" style={{ width: "100%", height: "40px", outline: "none", borderRadius: " 20px", border: "none", background: "#79AD3D", color: "white" }}
                onClick= {(e)=>{
                  e.preventDefault()
                  var win = window.open(props.data.linkGG, '_blank');
                  win.focus();
                }}
              >Tham gia</button>
            </Col>
          </div>
          
        </Row>
  }
  let  createMarkup = () => {
    return { __html: props.data.content.length > 150 ? props.data.content.substr(0, 100) + "..." : props.data.content};
  }
  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={6} className="plant">

      <Link to={"/du-an/" + props.data.id}>

        <Card
          hoverable
          cover={<img alt="thumbnail" className="thumbail" src={handleURLImage(props.data.thumbnail)} />}
        >
          <div>
            <h1 className="plant-name" style={{overflow: "hidden"}}>{
              props.data.title.indexOf(".") !== -1 && props.data.title.substring(4, props.data.title.indexOf(".")).length > 50 ? 
              props.data.title.substring(4, props.data.title.indexOf(".")).substr(0, 50) + "..." :
              props.data.title.indexOf(".") !== -1 && props.data.title.substring(4, props.data.title.indexOf(".")).length < 50 ?
              props.data.title.substring(4, props.data.title.indexOf(".")) : props.data.title.substr(4, 50)
            }</h1>
            <div className="plant-desc" style={{overflow: "hidden"}} dangerouslySetInnerHTML={createMarkup()}></div>
          </div>

          {
            props.data.status === 'suggest' ? checkStatus() : 
            <Row>
              <div  className="plant-footer" style={{ height: "40px"}}>
                <Col span={16}> <p className="plant-cost"></p></Col>
                <Col span={8}>
                  <button className="component-btn-join" style={{ width: "100%", height: "40px", outline: "none", borderRadius: " 20px", border: "none", background: "none"}}></button>
                </Col>
              </div>
            </Row>
          }
        </Card>
      </Link>
    </Col>
  )
}

export default Plant