import React, {} from 'react'
import './css/Home.css'
import Home from './Home'

function HomePage() {
    return (
        <div className="parent-home-page">
            <div className="home-page">
                <Home.Navbar />
                <div className="home-page-content">
                    <div className="content-title">
                        <h1>Planting trees,</h1>
                        <h1 style={{display: "inline"}}>Restoring the soil, nurture the soil,</h1>
                        <h1>Get involved.</h1>
                    </div>
                    <div className="content-team">
                        <p>Nhóm thực hiện: Kien Dang, Thai Vu, Hoa Le, Duoc Hoang, Vin Loc, Giang Le, Vu Pham <br /> và các nông dân sinh thái trẻ</p>
                        {/* <p>và các nông dân sinh thái trẻ</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomePage