import {get, post, patch, destroy} from '../utils/FetchAPI';

export const getAllAbout = (offset, limit) => get("/about", {offset:offset, limit:limit})
// export const uploadThumbnailImage = () => post("/plant/thumbnail")
// export const uploadDetailImage = () => post("/plant/list-image")
export const getAboutBySlug = (slug) => get("/about/"+slug)
// export const getPlantSuggestNotId = (id) => get("/plant/suggest-not-id/"+id)
export const createAbout = (body) => post("/about", body)
export const createAboutCk = (body) => post("/about/aboutCKeditor", body)
export const updateAbout = (id, body) => patch("/about/" + id, body)
export const deleteAbout = (id) => destroy("/about/"+id)