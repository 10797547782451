import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Detail.css';
import { IoArrowBack } from "react-icons/io5";
import { Popconfirm, Image, Button} from 'antd';
import Home from '../../Component/Home.jsx';
import { getPlantById, deletePlant } from '../../api/plant';
import { handleURLImage } from '../../utils/handleURLImage';
import { notification } from 'antd';
import UpdatePlant from '../../Component/UpdatePlant';

function list(data) {
    let listItems = null;
    if (Object.keys(data).length) {
        let listURLImage = data.imageDetail.map((url) => {
            return handleURLImage(url)
        });
        listItems = listURLImage.map((img, index) =>
            <div key={index} >
                <Image
                    className="item-img"
                    src={img}
                />
            </div>
        );
    }

    return listItems
}


let checkStatus = (data) => {
    return  <>
            <p className="money-item">
                <span className="item-price">Kinh phí thực hiện</span>
                <span className="item-money">{data.price && data.price.toLocaleString('en-EN', {
                    style : 'currency',
                    currency : 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                    })}</span>
            </p>
            <button style={{ width: "30%", height: "40px", outline: "none", borderRadius: " 20px", border: "none", background: "#79AD3D", color: "white"}}
                onClick= {()=>{
                    var win = window.open(data.linkGG, '_blank');
                    win.focus();
                }}
            >Tham gia</button>
        </>
}

function Detail(props) {
    let [data, setData] = useState({})
    let token = localStorage.getItem("token")

    useEffect(() => {
        let getPlant = async () => {
            try {
                let res = await getPlantById(props.match.params.id);
                if (res.status === 200) {
                    setData(res.data.data)
                }
            } catch (error) {

            }
        }
        getPlant()

    }, [props.match.params.id])
    let handleDeletePlant = async () => {
        try {
            let res = await deletePlant(props.match.params.id);
            if(res.status === 200){
                notification.success({
                    duration: 2,
                    message: "success",
                    description: res.data.message
                })
                window.location.href = "/du-an"
            }
        } catch (error) {
            if(error.response.status === 403) window.location.href = '/login'
            notification.error({
                duration: 2,
                message: "error",
                description: error.message
            })
        }
    }
    let  createMarkup = () => {
        return { __html: data.content };
      }
    return (
        <div className="item-container">
            <Home.Navbar ></Home.Navbar>
            <div className="item-container">
                <div className="item-next">
                    <a href="/du-an" className="item-a">
                        <IoArrowBack /> Tất cả dự án
                    </a>
                </div>
                <div className="detail-btn-create-update">
                    { token ? <div style={{width: '100%', textAlign: 'center', marginBottom: '15px'}}>
                            < UpdatePlant plant={data}/> 
                        </div>: null
                    } 
                    { token ? <div className="btn-delete" style={{width: '100%', textAlign: 'start', paddingLeft: "10px" , marginBottom: '15px'}}>
                                <Popconfirm placement="topLeft" title="Bạn muốn xóa dự án" onConfirm={handleDeletePlant} okText="Yes" cancelText="No">
                                    <Button type="primary" style={{  fontSize: "1.1rem", height: "auto"}}> 
                                        Xóa dự án
                                    </Button>
                                </Popconfirm>
                            </div>: null
                    
                    }
                </div>
                <div className="item-row">
                    <div className="item-col-5">
                        <Carousel
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            showThumbs={false}

                            swipeable={true}
                        >
                            {list(data)}

                        </Carousel>
                    </div>
                    <div className="item-col-5">
                        <div>
                            <h1 className="item-title">{data.title ? data.title.substring(4) : null}</h1>
                            <p className="item-conter" dangerouslySetInnerHTML={createMarkup()}></p>
                            {data.status === "suggest" ? checkStatus(data) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detail;

