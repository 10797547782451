import React from 'react'
import {Link} from 'react-router-dom';
function AboutList(props) {
  let next = props.currentPage + 1
  let prev = props.currentPage - 1

  let arrayButton = []
  let currentIndex = props.currentPage - 1
  let stop
  let start
  if(currentIndex < 2 ){
    start = 0
    stop = 4
  }
  if(currentIndex > props.page.length - 3){
    start = props.page.length - 5
    stop = props.page.length - 1
  }
  if(currentIndex >=2 && currentIndex <= props.page.length - 3){
    start = currentIndex -2
    stop = currentIndex + 2
  }
  let about = [...props.page]
  for(let i = start ; i <= stop ;i++){
    if(about[i]){
      about[i].page = i + 1
      arrayButton.push(props.page[i])  
    }
  }

  let nextSlug 
  let prevSlug 

  if(prev <= 0 && about[currentIndex+1]){
    prev = 1
    prevSlug = about[0].slug
    nextSlug = about[currentIndex+1].slug
  } else
  if(next >= about.length && about[currentIndex-1] && about[about.length-1] ){
    next = about.length
    prevSlug = about[currentIndex-1].slug
    nextSlug = about[about.length-1].slug
  }else
  if(prev > 0 && next < about.length && about[currentIndex+1]){
    prevSlug = about[currentIndex-1].slug
    nextSlug = about[currentIndex+1].slug
  }



  return (
    <div className='pageList'>
      <Link to= {"/gioi-thieu/"+prevSlug} >
          <button className='page' 
          disabled={currentIndex == 0 ? true : false}
          onClick={()=>{
          props.changePage(prev)
          }}
          > {'<<'} </button>
      </Link>

      {arrayButton.map((ele)=>{
        return (
          <Link to= {"/gioi-thieu/" + ele.slug} >
            <button className='page' 
            onClick={()=>{
            props.changePage(ele.page)
            }}>{ele.page}</button>
          </Link>
        )
      })}

      <Link to= {"/gioi-thieu/"+nextSlug } >
          <button className='page' 
          disabled={currentIndex == props.page.length - 1 ? true : false}
          onClick={()=>{
          props.changePage(next)
          }}
          >{'>>'}</button>
      </Link>
    </div>
  )
}

export default AboutList
