import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../Component/css/Create.css'
import { Modal, Button, Form, Input, Select, notification, DatePicker, Space } from 'antd';
import UploadListImage from './upload/UploadList';
import UpLoadThumnail from './upload/UpLoadThumnail';
import {updatePlant} from '../api/plant';
const { TextArea } = Input;
const { Option } = Select;

export default function UpdatePlant(props) {
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [listImage, setListImage] = useState([]);
    const [thumbnail, setThumbnail] = useState({});
    const [year, setYear] = useState(0)
    const [dataCk, setDataCk] = useState()
    function onChange(date, dateString) {
        setYear(dateString)
    }
    const showModal = () => {
        setIsModalCreate(true);
    };
    let onFinish = async (value) => {
        if(year === 0){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng hãy nhập số năm bắt đầu",
            })
        }else if(isNaN(Number(value.price))){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Kinh phí vui lòng hãy nhập số",
            })
        }else{
            let body = {
                title: year + value.title,
                content: dataCk,
                thumbnail: thumbnail,
                imageDetail: listImage,
                status: value.status,
                price: value.price
            }
            try {
                await updatePlant(props.plant.id, body)
                window.location.reload();
            } catch (error) {
                if(error.response.status === 403) window.location.href = '/login'
                notification.error({
                    duration: 2,
                    message: "Fail",
                    description: error.message,
                  })
            }
        }
    }
    let onCancel = () => {
        setIsModalCreate(false);
    }
    return (
        <div className="btn-modal-create"> 
            <Button type="primary" onClick={showModal} style={{ fontSize: "1.1rem", height: "auto"}}>
                Cập nhật dự án
            </Button>
            <Modal 
                title="Cập nhật dự án" 
                visible={isModalCreate} 
                onCancel={onCancel}
                footer={null}
            >
            <div className="content-create">
            <Form
                name="create-plant"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Tiêu đề"
                    name="title"
                    initialValue={props.plant.title ? props.plant.title.substring(4) : null}
                    rules={[{ required: true, message: 'Vui lòng hãy nhập tiêu đề !' }]}
                >
                    <TextArea placeholder="Tiêu đề"/>
                </Form.Item>
                <Form.Item name="status" label="Năm bắt đầu dự án:" rules={[{ required: true,message:"Vui lòng hãy chọn năm !" }]}>
                    <Space direction="vertical">
                        <DatePicker onChange={onChange} picker="year" placeholder="Năm " />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Nội dung"
                    name="content"
                    initialValue={props.plant.content}
                    rules={[{ required: true, message: 'Vui lòng hãy nhập nội dung !' }]}
                >
                    <CKEditor
                        config={{         
                            toolbar: ['heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList',
                             '|', 'undo', 'redo']
                        }}
                        editor={ ClassicEditor }
                        data={props.plant.content}
                        onBlur={ ( event, editor ) => {
                            const data = editor.getData();
                            setDataCk(data)
                        } }
                    />
                </Form.Item>
                <Form.Item 
                initialValue={props.plant.status}
                name="status" label="Trạng thái" rules={[{ required: true,message:"Vui lòng hãy chọn trạng thái !" }]}>
                    <Select
                        placeholder="Vui lòng chọn !"
                        allowClear
                    >
                        <Option value="suggest">Đề xuất</Option>
                        <Option value="doing">Đang làm</Option>
                        <Option value="done">Đã kết thúc</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="price" 
                    label="Kinh phí thực hiện"
                    initialValue={props.plant.price} 
                    rules={[{ required: true, message: 'Vui lòng hãy nhập kinh phí !' }]}>
                    <Input type="text" placeholder="Nhập kinh phí"/>
                </Form.Item>
                <Form.Item name="thumbnail" label="Ảnh đại diện">
                    <UpLoadThumnail setThumbnail={setThumbnail}/>
                </Form.Item>
                <Form.Item name="imageDetail" label="Ảnh chi tiết">
                    <UploadListImage setListImage={setListImage}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                         Cập nhật
                    </Button>
                    <Button type="primary" htmlType="submit" style={{background: "white !important", marginLeft: '10px'}} onClick={onCancel}>
                         Hủy
                    </Button>
                </Form.Item>
                </Form>
            </div>
            </Modal>
        </div>
    )
}
