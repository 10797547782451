import {get, post, patch, destroy} from '../utils/FetchAPI';

export const getContact = (offset, limit) => get("/contact", {})
// export const uploadThumbnailImage = () => post("/plant/thumbnail")
// export const uploadDetailImage = () => post("/plant/list-image")
// export const getAboutBySlug = (slug) => get("/about/"+slug)
// export const getPlantSuggestNotId = (id) => get("/plant/suggest-not-id/"+id)
export const createContact = (body) => post("/contact", body)
// export const createAboutCk = (body) => post("/about/aboutCKeditor", body)
export const updateContact = (id, body) => patch("/contact/" + id, body)
// export const deleteAbout = (id) => destroy("/about/"+id)