import React,{useState, useEffect} from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Button, Form, Input, notification } from 'antd';
import UploadListImage from './upload/UploadAbout'
import {createAbout, deleteAbout, updateAbout} from '../api/about'
const { TextArea } = Input;

function AddAbout(props) {
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [dataCk, setDataCk] = useState()
  const token = localStorage.getItem("token");

  const showModal = () => {
    setIsModalCreate(true);
};   
  let onCancel = () => {
    setIsModalCreate(false);
}
    let onFinish = async (data)=>{
        if(!data.title){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng nhập tiêu đề",
            })
        }else if(!dataCk){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng nhập nội dung",
            })
        }else if(!data.slug){
            notification.error({
                duration: 2,
                message: "Lỗi",
                description: "Vui lòng nhập nội dung",
            })
        }else{
            try {
                let body={
                    title : data.title,
                    slug: data.slug,
                    content : dataCk,
                }
                let response = await createAbout(body)
                if(response.status === 200){
                    notification.success({
                        duration: 2,
                        message: "Thành công",
                        description: response.data.message,
                    })
                    setIsModalCreate(false);
                    window.location.href = "/gioi-thieu"
                }
            } catch (error) {
                if(error.response.status === 403) window.location.href = '/login'
                notification.error({
                    duration: 2,
                    message: "Không thành công",
                    description: error.message,
                })
            }
        }
    }

    function onBlur(event, editor){
            const data = editor.getData();
            setDataCk(data)
    }
    let deleteAboutItem = async ()=>{
        try {
            if(props.data){
                let response = await deleteAbout(props.data.id)
                notification.success({
                    duration: 2,
                    message: "Thành công",
                    description: response.data.message,
                })
                setIsModalCreate(false);
                window.location.href = "/gioi-thieu"
            }
        } catch (error) {
            if(error.response.status === 403) window.location.href = '/login'
                notification.error({
                    duration: 2,
                    message: "Không thành công",
                    description: error.message,
                })
        }
    }
    async function updateAboutItem(){
        try {
            if(props.data){
                let title = document.getElementById('create-plant_title').value
                let slug = document.getElementById('create-plant_slug').value
                let body = {title : title, slug : slug, content : dataCk}
                let response = await  updateAbout(props.data.id,body)
                notification.success({
                    duration: 2,
                    message: "Thành công",
                    description: response.data.message,
                })
                setIsModalCreate(false);
                window.location.href = "/about"
            }
        } catch (error) {
            if(error.response.status === 403) window.location.href = '/login'
            notification.error({
                duration: 2,
                message: "Không thành công",
                description: error.message,
            })
        }
    }
  return (
      <div className="btn-modal-create"> 
            <Button type="primary" onClick={showModal} className="create-plant-btn">
               {props.job}
            </Button>
            <Modal 
                title={props.job}
                visible={isModalCreate} 
                onCancel={onCancel}
                footer={null}
            >
            <div className="content-create">
            <Form
                name="create-plant"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Tiêu đề"
                    name="title"
                    initialValue={props.data ? props.data.title : ''}
                    rules={[{ required: true, message: 'Vui lòng hãy nhập tiêu đề!' }]}
                >
                    <Input.TextArea 
                    placeholder="Tiêu đề"
                    />
                </Form.Item>
                <Form.Item
                    label="Đường dẫn"
                    name="slug"
                    initialValue={props.data ? props.data.slug : ''}
                    rules={[{ required: true, message: 'Vui lòng hãy nhập đường dẫn!' }]}
                >
                    <Input.TextArea placeholder="Đường dẫn"/>
                </Form.Item>
               
                <Form.Item
                    label="Nội dung:"
                    name="content"
                    rules={[{ required: true, message: 'Vui lòng hãy nhập nội dung!' }]}
                >
                    <CKEditor
                        editor={ ClassicEditor }
                        config={{ 
                            toolbar: ['heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList','uploadImage','link',
                             '|', 'undo', 'redo'],
                            ckfinder: {
                                uploadUrl:`${
                                    process.env.NODE_ENV !== "production"
                                      ? process.env.REACT_APP_BACKEND_DEV
                                      : process.env.REACT_APP_BACKEND_PROD
                                  }/about/aboutCKeditor`,
                                withCredentials: true,
                                headers: {
                                    'X-CSRF-TOKEN': 'CSRF-Token',
                                    Authorization: `Bearer ${token}`,
                                },
                            },                           
                        }}
                        data={props.data ? props.data.content : ''}
                        onBlur={ onBlur }
                    />
                </Form.Item>             
                <Form.Item>
                    {props.data? 
                    <Button type="primary" htmlType="submit" className='deleteBtn' style={{ marginRight: '10px'}} 
                    onClick={()=>{deleteAboutItem()}}>
                         Xóa thông tin này
                    </Button> : null}
                    {props.data?
                    <Button type="primary" 
                    onClick={()=>{updateAboutItem()}}>
                    Xác nhận
                    </Button>
                    : <Button type="primary" htmlType="submit" >Xác nhận</Button>
                    }
                    <Button type="primary" htmlType="submit" style={{background: "white !important", marginLeft: '10px'}} onClick={onCancel}>
                         Hủy
                    </Button>
                </Form.Item>
                </Form>
            </div>
            </Modal>
        </div>
   
  )
}

export default AddAbout
